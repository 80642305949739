export const secondrySubjects = [
  { id: "1", name: "English Language" },
  { id: "2", name: "Mathematics" },
  { id: "3", name: "Islamic Studies" },
  { id: "4", name: "Computer Science" },
  { id: "5", name: "Agricultural Science" },
  { id: "6", name: "General Mathematics" },
  { id: "7", name: "Biology" },
  { id: "8", name: "Physics" },
  { id: "9", name: "Chemistry" },
  { id: "10", name: "Further Mathematics" },
  { id: "11", name: "Health and Physical Education" },
  { id: "12", name: "Technical Drawing" },
  { id: "13", name: "Food and Nutrition" },
  { id: "14", name: "Financial Accounting" },
  { id: "15", name: "Book Keeping" },
  { id: "16", name: "Typewriting" },
  { id: "17", name: "Commerce" },
  { id: "18", name: "Data Processing" },
  { id: "19", name: "Economics" },
  { id: "20", name: "Government" },
  { id: "21", name: "Civic Education" },
  { id: "22", name: "Geography" },
  { id: "22", name: "History" },
  { id: "22", name: "Fisheries" },
  { id: "23", name: "Hausa" },
];

export const secondryScores = [
  { id: "1", name: "A1" },
  { id: "2", name: "B2" },
  { id: "3", name: "B3" },
  { id: "4", name: "C4" },
  { id: "5", name: "C5" },
  { id: "6", name: "C6" },
  { id: "7", name: "D7" },
  { id: "8", name: "E8" },
  { id: "9", name: "F9" },
  { id: "10", name: "Awaiting Result" },
];

export const programmes = [
  {
    id: "0",
    name: "Higher National Diploma",
    programs: [
      {
        id: 1,
        name: "HND in Environmental Health Technology (HND-EHT)",
      },
      {
        id: 2,
        name: "HND in Health Information Management (HND-HIM)",
      },
      {
        id: 3,
        name: "HND in Dental Therapy (HND-DT)",
      },
    ],
  },
  {
    id: "1",
    name: "National Diploma",
    programs: [
      {
        id: 1,
        name: "ND in Public Health Technology (ND-PHT)",
      },
      {
        id: 2,
        name: "ND in Epidemiology and Disease Control Technology (ND-EDT)",
      },
      {
        id: 3,
        name: "ND in Environmental Health Technology (ND-EHT)",
      },
      {
        id: 4,
        name: "ND in Dental Therapy (ND-DT)",
      },
      {
        id: 5,
        name: "ND in Health Information Management (ND-HIM)",
      },
      {
        id: 6,
        name: "ND in Community Health (ND-CH)",
      },
    ],
  },
  {
    id: "2",
    name: "Professional Diploma",
    programs: [
      {
        id: 2,
        name: "Diploma in Medical Laboratory Technician (MLT)",
      },
      {
        id: 3,
        name: "Diploma in Pharmacy Technician (DPT)",
      },
      {
        id: 4,
        name: "Diploma in Community Health (CHEW)",
      },
    ],
  },
  {
    id: "3",
    name: "Certificates",
    programs: [
      {
        id: 1,
        name: "Certificate in Community Health (JCHEW)",
      },
    ],
  },
];

export const departments = [
  {
    id: "1",
    name: "Department of Computer Science",
    programs: [
      {
        name: "Computer Science",
        id: "1",
      },
    ],
  },
  {
    id: "2",
    name: "Department of Public Administration",
    programs: [
      {
        name: "Public Administration",
        id: "2",
      },
    ],
  },

  {
    id: "3",
    name: "Department of Office Technology Management",
    programs: [
      {
        name: "Office Technology Management",
        id: "3",
      },
    ],
  },
];

export const diplomaPrograms = [
  { id: "1", name: "Computer Science" },
  { id: "2", name: "Public Administration" },
  { id: "3", name: "Office Technology Management" },
];

export const requiredRegistrationKeys = [
  "FirstName",
  "Surname",
  "PhoneNumber",
  "Email",
  "Address",
  "State",
  "LGA",
  "Gender",
  "MaritalStatus",
  "DoB",
  "PrimarySchool",
  "SecondarySchool",
  "PrimaryYear",
  "SecondaryYear",
  "ParentOrGuardianName",
  "ParentOrGuardianPhone",
  "ParentOrGuardianAddress",
];

export const admissionProgrammes = [
  {
    department: "Community Health",
    programmes: [
      {
        programme: "Certificate in Community Health",
        programmeCode: "JCHEW",
        duration: "2 Years",
      },
      {
        programme: "Diploma in Community Health",
        programmeCode: "CHEW",
        duration: "3 Years",
      },
      {
        programme: "ND in Community Health",
        programmeCode: "ND CHEW",
        duration: "3 Years",
      },
    ],
  },
  {
    department: "Medical Laboratory Science",
    programmes: [
      {
        programme: "Diploma in Medical Laboratory Technician",
        programmeCode: "MLT",
        duration: "3 Years",
      },
    ],
  },
  {
    department: "Public Health",
    programmes: [
      {
        programme: "ND in Public Health Technology",
        programmeCode: "ND PHT",
        duration: "2 Years",
      },
    ],
  },
  {
    department: "Health Information Management",
    programmes: [
      {
        programme: "ND in Health Information Management",
        programmeCode: "ND HIM",
        duration: "2 Years",
      },
      {
        programme: "HND in Health Information Management",
        programmeCode: "HND HIM",
        duration: "2 Years",
      },
    ],
  },
  {
    department: "Pharmacy Technician",
    programmes: [
      {
        programme: "Diploma in Pharmacy Technician",
        programmeCode: "DPT",
        duration: "3 Years",
      },
    ],
  },
  {
    department: "Dental Health",
    programmes: [
      {
        programme: "ND in Dental Therapy",
        programmeCode: "ND DT",
        duration: "2 Years",
      },
      {
        programme: "HND in Dental Therapy",
        programmeCode: "HND DT",
        duration: "2 Years",
      },
    ],
  },
  {
    department: "Environmental Health",
    programmes: [
      {
        programme: "ND in Environmental Health",
        programmeCode: "ND EH",
        duration: "2 Years",
      },
      {
        programme: "HND in Environmental Health",
        programmeCode: "HND EH",
        duration: "2 Years",
      },
    ],
  },
];

export const accesses = [
  { name: "Finance", code: "finance" },
  { name: "Application", code: "application" },
  { name: "Admision", code: "admission" },
  { name: "Site Administrator", code: "siteAdmin" },
  { name: "College Officer", code: "officer" },
  { name: "Security Officer", code: "so" },
];

export const officers = [
  { name: "Head of Dept", role: "hod" },
  { name: "Student affairs", role: "sao" },
  { name: "Burser", role: "burser" },
  { name: "Account officer", role: "ao" },
  { name: "Registerer", role: "registerer" },
];

export default departments;
